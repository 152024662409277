import AwsS3 from "@uppy/aws-s3";
import AwsS3Multipart from "@uppy/aws-s3-multipart";
import Compressor from "@uppy/compressor";
import Uppy from "@uppy/core";
import Dashboard from "@uppy/dashboard";
import DropTarget from "@uppy/drop-target";
import GoldenRetriever from "@uppy/golden-retriever";
import RemoteSources from "@uppy/remote-sources";
import Transloadit from "@uppy/transloadit";
import Tus from "@uppy/tus";
import XHRUpload from "@uppy/xhr-upload";
import React, { useEffect, useRef } from "react";

import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import "./UppyUploadBox.css";

const UPLOADER = "tus"; // "tus" | "s3" | "s3-multipart" | "xhr"
const COMPANION_URL = "http://companion.uppy.io";
const TUS_ENDPOINT = "https://dev.uploader.hemehealth.app/files/";
const XHR_ENDPOINT = "";
const RESTORE = false;

const UppyUploader = ({ onUploadComplete, allowedFileTypes }) => {
  const uppyInstance = useRef(null); // Use useRef to store the Uppy instance

  useEffect(() => {
    const uppyDashboard = new Uppy({
      restrictions: {
        allowedFileTypes: allowedFileTypes.length > 0 ? allowedFileTypes : null,
      },
      autoProceed: false, // Ensure files aren't auto-uploaded when added
    })
      .use(Dashboard, {
        inline: true,
        target: "#uppy",
        showProgressDetails: true,
        width: "100%",
        height: 450,
      })
      .use(RemoteSources, {
        companionUrl: COMPANION_URL,
        sources: [],
      })
      .use(Transloadit, {
        importFromUploadURLs: true,
        assemblyOptions: {
          params: {
            auth: { key: "d237f4c26e0742148dd8aa5a7fc5ff80" },
            template_id: "a5704276b30e4edb988c71b5b4497dca",
          },
        },
        waitForEncoding: true,
      })
      .use(DropTarget, {
        target: document.body,
      })
      .use(Compressor);

      switch (UPLOADER) {
        case "tus":
          uppyDashboard.use(Tus, { endpoint: TUS_ENDPOINT, limit: 6 });
          break;
        case "s3":
          uppyDashboard.use(AwsS3, { companionUrl: COMPANION_URL, limit: 6 });
          break;
        case "s3-multipart":
          uppyDashboard.use(AwsS3Multipart, {
            companionUrl: COMPANION_URL,
            limit: 6,
          });
          break;
        case "xhr":
          uppyDashboard.use(XHRUpload, {
            endpoint: XHR_ENDPOINT,
            limit: 6,
            bundle: true,
          });
          break;
        default:
      }
  
      if (RESTORE) {
        uppyDashboard.use(GoldenRetriever, { serviceWorker: true });
      }
  
      // Store the instance in the ref
      uppyInstance.current = uppyDashboard;

    uppyDashboard.on("transloadit:assembly-created", (assembly, fileIDs) => {
      onUploadComplete(assembly?.assembly_id);
    });

    uppyDashboard.on("transloadit:complete", (assembly) => {
      if (assembly) {
        if (onUploadComplete) {
          onUploadComplete({ assemblyID: assembly?.assembly_id });
        }
      }
    });

    return () => {
      if (uppyInstance.current) {
        uppyInstance.current.cancelAll();  // Cancel ongoing uploads
        uppyInstance.current.close();  // Close the Uppy dashboard
        uppyInstance.current = null;   // Clean up reference
      }
    };
  }, [onUploadComplete]);

  return <div id="uppy" className="uppy-uploader-container"></div>;
};

export default UppyUploader;
