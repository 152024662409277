import { NavLink as RouterLink } from "react-router-dom";
import styled from "styled-components";
import { DEVICE } from "../../../utilities/constants";

export const CloseIcon = styled.img`
position: absolute;
right: 0;
`
export const SideNavbar = styled.div`
  border-radius: 1rem;
  width: 260px;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 32px #2477b314;
  opacity: 1;
  margin: 24px 0 0 48px;
  border-radius: 12px;
  display: block;
  padding: 1rem;
  height: calc(100px + 100vh);

  @media ${DEVICE.tablet} {
    display: none;
  }
`;

export const NavLink = styled(RouterLink)`
  display: flex;
  align-items: center;
  height: 42px;
  color: #808080;
  text-decoration: none;
  cursor: pointer;
  font-size: 16px;
  padding-left: 10px;
  gap: 10px;
  margin: 0 16px;
  justify-content: flex-start;

  &.active {
    background-color: #F5FBFC;
    border-radius: 8px;
    color: #0099cc;
  }
`;

export const TopHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  color: #0099CC;;
  margin-top: 24px;
  cursor: pointer;
`;

export const ArrowBackImage = styled.img`
`;

export const ProfileIcon = styled.img`
  width: 48px;
  height: 48px;
  margin: 24px;
`;;
export const PatientName = styled.div`
  font-size: 16px;
  color: #0099CC;
  margin-bottom: 5px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  white-space: normal; 
  word-break: break-all; 
`;
export const PhoneNumber = styled.div`
  font-size: 14px;
  color: #000000;
  margin-bottom: 5px;
  width: 170px;
  overflow: hidden;
  text-overflow: ellipsis;
 `;

export const Email = styled.div`
  font-size: 14px;
  color: #000000;
  margin-bottom: 16px;
  width: 170px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  white-space: normal; 
  `

export const SidebarImage = styled.img`
  margin-right: 10px;
`;
export const DetailItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 7px;
  margin-bottom: 8px;
`;
export const DetailItemDob = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
export const GenderImage = styled.img`
`;
export const DobImage = styled.img`
  width: 16px;
  height: 16px;
`;
export const GenderText = styled.div`
  color:#808080;
  font-size: 14px;
`;
export const DobText = styled.div`
  color:#808080;
  font-size: 14px;
`;
export const DobDate = styled.span`
`;
export const DobYears = styled.span`
  color: #808080;
`;
export const NextArrowIcon = styled.img`
    cursor: pointer;
    width: 24px;
    height: 24px;
    flex: 1;
`;

export const EmailText = styled.div`
    font-size: 14px;
    color: #000000;
    max-width: 170px;   
    word-wrap: break-word;
    white-space: normal;    
    word-break: break-all;
`;

export const EmailItem = styled.div`
  display: flex;
  align-items: start;
  gap: 8px;
  margin-top: 7px;
`;

export const EmailImage = styled.img`
  height: 14px;
  margin-top: 7px;
`;