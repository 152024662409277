import AwsS3 from "@uppy/aws-s3";
import AwsS3Multipart from "@uppy/aws-s3-multipart";
import Compressor from "@uppy/compressor";
import Uppy from "@uppy/core";
import Dashboard from "@uppy/dashboard";
import DropTarget from "@uppy/drop-target";
import GoldenRetriever from "@uppy/golden-retriever";
import RemoteSources from "@uppy/remote-sources";
import Transloadit from "@uppy/transloadit";
import Tus from "@uppy/tus";
import XHRUpload from "@uppy/xhr-upload";
import React, { useEffect, useRef } from "react";
import { authSelector } from "../../../store/slice/auth.slice";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import "./UppyUploadBox.css";
import { useSelector } from "react-redux";

const UPLOADER = "tus"; // "tus" | "s3" | "s3-multipart" | "xhr"
const COMPANION_URL = "http://companion.uppy.io";
const TUS_ENDPOINT = "https://dev.uploader.hemehealth.app/files/";
const XHR_ENDPOINT = "";
const RESTORE = false;

const UppyUploader = ({ onUploadComplete, allowedFileTypes }) => {
  const uppyInstance = useRef(null); // Use useRef to store the Uppy instance
  const { selectedCountry } = useSelector(authSelector);

  useEffect(() => {
    const uppyDashboard = new Uppy({})
      .use(Dashboard, {
        inline: true,
        target: "#uppy",
        showProgressDetails: true,
        // proudlyDisplayPoweredByUppy: true,
        width: "100%",
        height: 272,
      })
      .use(RemoteSources, {
        companionUrl: COMPANION_URL,
        sources: [],
      })
      .use(Transloadit, {
        importFromUploadURLs: true,
        assemblyOptions: {
          params: {
            auth: { key: "d237f4c26e0742148dd8aa5a7fc5ff80" },
            template_id: selectedCountry?.code === "IN" ? "1e2e9fcc9a7543498b9019613083ed8e" : "2bf083946dbb472aa763448ea85ef172",
          },
        },
        waitForEncoding: true,
      })
      .use(DropTarget, {
        target: document.body,
      })
      .use(Compressor);

    switch (UPLOADER) {
      case "tus":
        uppyDashboard.use(Tus, { endpoint: TUS_ENDPOINT, limit: 6 });
        break;
      case "s3":
        uppyDashboard.use(AwsS3, { companionUrl: COMPANION_URL, limit: 6 });
        break;
      case "s3-multipart":
        uppyDashboard.use(AwsS3Multipart, {
          companionUrl: COMPANION_URL,
          limit: 6,
        });
        break;
      case "xhr":
        uppyDashboard.use(XHRUpload, {
          endpoint: XHR_ENDPOINT,
          limit: 6,
          bundle: true,
        });
        break;
      default:
    }

    if (RESTORE) {
      uppyDashboard.use(GoldenRetriever, { serviceWorker: true });
    }

    window.uppy = uppyDashboard;

    uppyDashboard.on("transloadit:assembly-created", (assembly, fileIDs) => {
      onUploadComplete(assembly?.assembly_id);
      fileIDs.forEach(id => {
        const file = uppyDashboard.getFile(id);
        console.log('File:', file);
      });
    });

    uppyDashboard.on("file-removed", (file) => {
      console.log(`File removed: ${file.name}`);
    });

    uppyDashboard.on("transloadit:complete", (assembly) => {
      let uploadObject = [];
    
      // Check if there are objects in assembly?.results?.convert_to_pdf
      if (assembly?.results?.convert_to_pdf) {
        // Merge convert_to_pdf objects with uploads, but exclude "image" files
        uploadObject = [
          ...assembly?.results?.convert_to_pdf,
          ...assembly?.uploads.filter(upload => !upload.mime.includes('image')),
        ];
      } else {
        // If convert_to_pdf is not present, take only the uploads, filtering out "image" files
        uploadObject = assembly?.uploads.filter(upload => !upload.mime.includes('image'));
      }
    
      // Map the final uploadObject to the required structure
      const uploadedFiles = uploadObject.map((upload) => ({
        file_id: upload.id,
        file_name: upload.name,
        mimetype: upload.mime,
        file_size: upload.size,
      }));
    
      // Call the onUploadComplete function with the final mapped uploadedFiles
      if (onUploadComplete) {
        onUploadComplete({ assemblyID: assembly?.assembly_id, uploadedFiles });
      }
    });    

    return () => {
      uppyDashboard.close();
    };
  }, []);

  return <div id="uppy" className="uppy-uploader-container"></div>;
};

export default UppyUploader;
