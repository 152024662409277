import React, { useState, useEffect, useRef } from 'react';
import { ParentContainer, DragFilesText, UploadMainContainer, AddDocsContainer, AddScansContainer, PlusText, AddText, BackButtonContainer, BackArrowImg, BackText, NextButton } from './style.components';
import UppyUploader from '../../components/layout/UppyUploadBox/UppyUploadBox';
import UppyUploaderScans from '../../components/layout/UppyUploadBoxScans/UppyUploadBox';
import UppyUploaderEncounter from '../../components/layout/UppyUploadBoxEncounter/UppyUploadBox'; // Import the new UppyUploader
import { IBackArrow } from '../../assets';
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { getUploadRecordsAction } from '../../store/actions';
import { Loader, SnackBar } from '../../components/shared';
import { setRecords, setUppyResponse } from '../../store/slice';

const AddRecords = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [fileTypes, setFileTypes] = useState([]);
  const [showUploader, setShowUploader] = useState(false);
  const [uppyResponse, setUppyLibResponse] = useState();
  const [loading, setLoading] = useState(false);
  const [recordType, setRecordType] = useState("");
  const [isUppyReady, setIsUppyReady] = useState(false);
  const uppyInstance = useRef(null);

  const isNewEncounter = location.pathname === "/new-encounter/add-records";
  const generatedFiles = isNewEncounter && location.state?.generatedFiles;

  // Wait for uppyInstance to be ready before uploading files
  useEffect(() => {
    if (generatedFiles && isUppyReady && uppyInstance.current) {
      setShowUploader(true);

      // Add each file to the Uppy instance and trigger the upload
      generatedFiles.forEach((file) => {
        uppyInstance.current.addFile({
          name: file.name,
          type: file.type,
          data: file.blob,
        });
      });

      // Programmatically trigger the upload once files are added
      uppyInstance.current.upload().then((result) => {
        if (result.failed.length === 0) {
          console.log("Files uploaded successfully");
        } else {
          console.error("Some files failed to upload:", result.failed);
        }
      });
    }
  }, [generatedFiles, isUppyReady]);

  const handleAddDocsClick = () => {
    setFileTypes(['.pdf', '.jpg', '.jpeg', '.png', '.rtf', '.doc']);
    setShowUploader(true);
    setRecordType("docs");
  };

  const handleAddScansClick = () => {
    setFileTypes(['.dcm', '.zip']);
    setShowUploader(true);
    setRecordType("scans");
  };

  const handleBackClick = () => {
    setShowUploader(false);
  };

  const handleNextButton = async () => {
    setLoading(true);
    try {
      dispatch(setUppyResponse(uppyResponse));
      const response = await dispatch(getUploadRecordsAction(uppyResponse?.assemblyID));
      dispatch(setRecords(response.payload));
      navigate('send-records');
    } catch (error) {
      console.error("Error sending records:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <ParentContainer>
        <DragFilesText>Click or Drag your files here</DragFilesText>
        <UploadMainContainer>
          {!showUploader && (
            <>
              <AddDocsContainer onClick={handleAddDocsClick}>
                <PlusText>+</PlusText>
                <AddText>Documents</AddText>
              </AddDocsContainer>
              {/* <AddScansContainer onClick={handleAddScansClick}>
                <PlusText>+</PlusText>
                <AddText>Scans/Dicoms</AddText>
              </AddScansContainer> */}
            </>
          )}

          {showUploader && (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', flex: '1' }}>
            {!isNewEncounter ? (
              <BackButtonContainer onClick={handleBackClick}>
                <BackArrowImg src={IBackArrow} />
                <BackText>{`Back`}</BackText>
              </BackButtonContainer>
            ) 
            : null 
            }
              <div style={{ width: '100%' }}>
                {isNewEncounter ? (
                  <UppyUploaderEncounter 
                    onUploadComplete={setUppyLibResponse} 
                    allowedFileTypes={fileTypes} 
                    uppyRef={uppyInstance}
                    setIsUppyReady={setIsUppyReady} // Pass setIsUppyReady to UppyUploaderEncounter
                  />
                ) : recordType === "docs" ? (
                  <UppyUploader 
                    onUploadComplete={setUppyLibResponse} 
                    allowedFileTypes={fileTypes} 
                  />
                ) : (
                  <UppyUploaderScans 
                    onUploadComplete={setUppyLibResponse} 
                    allowedFileTypes={fileTypes}
                  />
                )}
              </div>

              {uppyResponse?.uploadedFiles && (
                <div style={{ width: '100%' }}>      
                  <NextButton onClick={handleNextButton}>
                    {loading ? <Loader size={24} /> : "Next"}
                  </NextButton> 
                </div>
              )}
            </div>
          )}
        </UploadMainContainer>
      </ParentContainer>
      <SnackBar />
    </>
  );
};

export default AddRecords;
