import styled from "styled-components";

export const ParentContainer = styled.div`
    padding: 40px;
`;

export const DragFilesText = styled.div`
    color: #0099CC;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 20px;
`;

export const UploadMainContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

export const AddDocsContainer = styled.div`
    background-color: #EBF7FB;
    border-radius: 4px;
    border: 1px dashed #92D4E9;;
    width: 98%;
    min-height: calc(100vh - 300px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
`;

export const AddScansContainer = styled.div`
    background-color: #EBF7FB;
    border-radius: 4px;
    border: 1px dashed #92D4E9;;
    width: 49%;
    min-height: calc(100vh - 300px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
`;

export const PlusText = styled.div`
    font-size: 50px;
    color: #808080;
    margin-bottom: 10px;
    font-wight: bold;
`;

export const AddText = styled.div`
    font-size: 16px;
    color: #808080;
`;

export const NextButton = styled.button`
  height: 44px;
  background: #7ABA56;
  cursor: pointer;
  border-radius: 8px;
  color: #ffffff;
  outline: none;
  border-style: hidden;
  font-size: 16px;
  margin-left: 20px;
  width: 220px;
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  float: right;
`;


export const BackButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 40px;
  cursor: pointer;
`;
export const BackArrowImg = styled.img`
  width: 32px;
  height: 32px;
`;
export const BackText = styled.div`
  color: #000000;
  font-size: 16px;
`;