import { createSlice } from "@reduxjs/toolkit";
import { getUploadRecordsAction } from "../actions";

const initialState = {
  records: [],
  uppyResponse: null,
  splitRecords: {}, // Initialize splitRecords as an empty object
  isLoading: false,
};

const recordsUppySlice = createSlice({
  name: "records",
  initialState,
  reducers: {
    setRecords(state, action) {
      state.records = action.payload;
    },
    setUppyResponse(state, action) {
      state.uppyResponse = action.payload;
    },
    // Updated action to handle splitRecords defensively
    updateSplitRecords(state, action) {
      const { originalFileName, splitFiles } = action.payload;

      // Ensure splitRecords is always initialized as an object
      if (!state.splitRecords) {
        state.splitRecords = {};
      }

      // If no split record exists for the original file name, initialize it
      if (!state.splitRecords[originalFileName]) {
        state.splitRecords[originalFileName] = [];
      }

      // Append the new split files to the existing array
      state.splitRecords[originalFileName] = [
        ...state.splitRecords[originalFileName],
        ...splitFiles,
      ];

      console.log(state.splitRecords, "updated splitRecords");
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUploadRecordsAction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getUploadRecordsAction.fulfilled, (state, { payload }) => {
      state.records = payload;
      state.isLoading = false;
    });
    builder.addCase(getUploadRecordsAction.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const { setRecords, setUppyResponse, updateSplitRecords } = recordsUppySlice.actions;

export const recordsUppySelector = (state) => ({
  records: state.records.records,
  splitRecords: state.records.splitRecords,
  uppyResponse: state.records.uppyResponse,
});

export default recordsUppySlice.reducer;
