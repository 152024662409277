import React, { useEffect, useState } from "react";
import { IBackArrow, INextArrow } from "../../../assets";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SnackBar } from "../../../components/shared";
import { ShimmerContentBlock } from "react-shimmer-effects";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import {
  TopHeader,
  CardsContainer,
  Container,
  ListDateTitle,
  ListSection,
  ListWrapper,
  LoaderWrapper,
  StyledCloseButton,
  StyledImage,
  StyledModal,
  StyledModalContent,
  ListItemTop,
  NextArrow,
  ListItemDate,
  SeeMoreText,
  ListItemWrapper,
  SeeMoreWrapper,
} from "./style.components";
import { GlobalWorkerOptions } from "pdfjs-dist";
import { getRecordsAction } from "../../../store/actions";
import { changeStatusRestrictedToAvailableAction } from "../../../store/actions/records.action";
import { Grid, Box, CircularProgress } from "@mui/material";
import RecordItem from "../../../components/layout/RecordItem/RecordItem";
import {
  getReportURL,
  getThumbnails,
} from "../../../store/actions/records.action";
import ChangeStatusDialog from "../../../components/shared/ChangeStatusDialog/ChangeStatusDialog";
import { toast } from "react-toastify";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { resetThumbnail } from "../../../store/slice/thumbanils.slice"; 
import NoteRecordItem from "../../../components/layout/NoteRecordItem/NoteRecordItem";

const workerUrl = `//${window.location.host}/pdf.worker.min.js`;
GlobalWorkerOptions.workerSrc = workerUrl;

const SeeMoreRecords = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { recordType, recordName, patientID } = location.state || {};
  const [recordsData, setRecordsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [open, setOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const screenWidth = window.innerWidth;
  const isMobileWidth = screenWidth <= 768;
  const [storeData, setStoreData] = useState(null);
  const [isShowhangeStatus, setShowChangeStatus] = useState(false);
  const [issStatusChangeLoading, setIsStatusChangeLoading] = useState(false);
  const [pageToken, setPageToken] = useState("");
  const thumbnails = useSelector((state) => state?.thumbnail?.thumbnails);
  const [loadingSeeMore, setLoadingSeeMore] = useState(false);

  useEffect(() => {
    if (patientID) {
      setIsLoading(true);
      fetchRecords(recordType);
    }
  }, [patientID, recordType, dispatch]);

  const fetchThubnailImages = async (isResetThumbnail=false) => {
    if (
      recordType === "scan" ||
      recordType === "notes" ||
      recordType === "questionnaire"
    )
      return;
    const filePaths = recordsData
      .filter((item) => item?.file_path && (isResetThumbnail || !thumbnails?.[item.id]))
      .map((item) => ({
        file_path: item.file_path,
        fhir_id: item.id,
      }));
    filePaths.length &&
      dispatch(
        getThumbnails({
          file_objects: filePaths,
        })
      );
  };

  useEffect(() => {
    if (recordsData?.length) fetchThubnailImages();
  }, [recordsData]);

  useEffect(() => {
    dispatch(resetThumbnail());
    const interval = setInterval(() => {
      fetchThubnailImages(true);
    }, 300000);

    return () => clearInterval(interval);
  }, [dispatch]);

  const closeModal = () => {};

  const fetchRecords = async (recordType, isUpdate=false) => {
    if (patientID) {
      try {
        const action = (pageToken && !isUpdate)
          ? await dispatch(
              getRecordsAction({
                record_type: recordType,
                patient_id: patientID,
                limit: 10,
                page_token: pageToken,
              })
            )
          : await dispatch(
              getRecordsAction({
                record_type: recordType,
                patient_id: patientID,
                limit: 10,
              })
            );
        if (action.payload.status === "success") {
          const fetchedData = action?.payload?.data;
          const totalCount = action?.payload?.total;
          const page_token = action?.payload?.page_token;
          isUpdate ?  setRecordsData([...fetchedData]) : setRecordsData([...recordsData, ...fetchedData]);
          setTotal(totalCount);
          setPageToken(page_token);
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error fetching records:", error);
      } finally {
        setLoadingSeeMore(false);
        setIsLoading(false);
      }
    }
  };

  const handleScanClick = (fhir_id) => {
    navigate("/patient-records/scan-info", {
      state: {
        studyFhirId: fhir_id,
      },
    });
  };

  const onChangeStatus = () => {
    setShowChangeStatus(true);
  };

  const onStatusAvailable = async (status) => {
    if (status) {
      setIsStatusChangeLoading(true);
      try {
        const payload = {
          record_type: storeData?.record_type,
          id: storeData?.id || storeData?.fhir_id,
          status: status,
        };
        const response = await dispatch(
          changeStatusRestrictedToAvailableAction({ payload: payload })
        );
        if (response && response?.payload?.status === "success") {
          storeData?.record_type === 'scan' ?  await fetchRecords("scans", true) : await fetchRecords(storeData?.record_type, true);
          toast.success(
            response?.payload?.message,
            {
              position: toast.POSITION.BOTTOM_RIGHT,
            },
            500
          );
        } else {
          alert("Something went wrong!");
        }
        setShowChangeStatus(false);
        setIsStatusChangeLoading(false);
      } catch (err) {
        console.log(err);
        setShowChangeStatus(false);
        setIsStatusChangeLoading(false);
        alert("Something went wrong!");
      }
    } else {
      setShowChangeStatus(false);
      setIsStatusChangeLoading(false);
    }
  };
  const handleSeeMore = () => {
    setLoadingSeeMore(true);
    fetchRecords(recordType);
  };

  const handleClickDropdownMenu = () => {
    navigate("/patient-records/share", {
      state: {
        data: storeData,
      },
    });
  };


  const fetchReportUrl = async (filePath) => {
    try {
      const payload = {
        file_path: filePath,
      };
      const response = await dispatch(getReportURL({ payload: payload }));
      return response?.payload?.data;
    } catch (error) {
      console.log("error occured while fetching url");
      return "";
    }
  };

  const handleListItemClick = async (record) => {
    if (record?.record_type === "scans") {
      const url = isMobileWidth
        ? record?.mobile_viewer_url
        : record?.dicom_viewer_url;
      const newWindow = window.open("", "_blank");
      if (newWindow) newWindow.location.href = url;
    } else if (record?.record_type === "questionnaire") {
      navigate("questionnaire-type/questionnaire-form", {
        state: { filled_form_id: record?.id },
      });
    } else if (record?.name === "Notes") {
      return;
    } else if (
      record?.record_type !== "questionnaire" ||
      record?.record_type !== "scans" ||
      record?.record_type !== "notes"
    ) {
      const url = await fetchReportUrl(record?.file_path);
      setSelectedRecord({ ...record, url: url });
      setOpen(true);
    }
  };

  const menuItems = [
    { label: "Share", onClick: () => handleClickDropdownMenu() },
  ];
  const menuItemsWithStatusChange = [
    { label: "Share", onClick: () => handleClickDropdownMenu() },
    { label: "Change Status", onClick: () => onChangeStatus() },
  ];

  return (
    <Container>
      <TopHeader>
        <ChevronLeftIcon
          sx={{ cursor: "pointer" }}
          onClick={() => navigate(-1)}
        />
        Back
      </TopHeader>
      <ListWrapper>
        <>
          <ListSection>
            <ListDateTitle>
              {recordName} {total > 0 ? `(${total})` : ""}
            </ListDateTitle>
          </ListSection>
          {!isLoading ? (
           <Grid container gap={2} mt={4}>
           {(() => {
             switch (recordType) {
               case "scans":
                 return (
                   <>
                     {recordsData?.map((row, index) => (
                       <RecordItem
                         key={row.id}
                         reportType={"scan"}
                         fetchUrlForFile={() => handleScanClick(row?.id)}
                         setStoreData={setStoreData}
                         isShowDropdown={true}
                         menuItems={menuItemsWithStatusChange}
                         row={row}
                         index={index}
                       />
                     ))}
                     {total > 9 &&
                       recordsData.length < total &&
                       (!loadingSeeMore ? (
                         <ListItemWrapper key="see-more">
                           <ListItemTop style={{ visibility: "hidden" }}>
                             hidden
                           </ListItemTop>
                           <SeeMoreWrapper onClick={handleSeeMore}>
                             <SeeMoreText>See More</SeeMoreText>
                             <NextArrow src={INextArrow} />
                           </SeeMoreWrapper>
                           <ListItemDate style={{ visibility: "hidden" }}>
                             hidden
                           </ListItemDate>
                           <ListItemDate style={{ visibility: "hidden" }}>
                             hidden
                           </ListItemDate>
                         </ListItemWrapper>
                       ) : (
                         <Box
                           display="flex"
                           ml={4}
                           alignItems="center"
                           justifyContent="center"
                         >
                           <CircularProgress />{" "}
                         </Box>
                       ))}
                   </>
                 );
               case "notes":
                 return (
                   <>
                     {" "}
                     {recordsData?.map((row, index) => (
                        <NoteRecordItem row={row} index={index} />
                     ))}
                     {total > 9 &&
                       recordsData.length < total &&
                       (!loadingSeeMore ? (
                         <Box>
                           <ListItemWrapper key="see-more">
                             <Box
                               onClick={handleSeeMore}
                               sx={{
                                 width: 180,
                                 height: 250,
                                 display: "flex",
                                 justifyContent: "center",
                                 alignItems: "center",
                                 borderRadius: "8px",
                                 textAlign: "center",
                                 background: "#ebf7fb",
                               }}
                             >
                               <SeeMoreText>See More</SeeMoreText>
                               <NextArrow src={INextArrow} />
                             </Box>
                             <ListItemDate style={{ visibility: "hidden" }}>
                               hidden
                             </ListItemDate>
                             <ListItemDate style={{ visibility: "hidden" }}>
                               hidden
                             </ListItemDate>
                           </ListItemWrapper>
                         </Box>
                       ) : (
                         <Box
                           display="flex"
                           m={2}
                           alignItems="center"
                           justifyContent="center"
                         >
                           <CircularProgress />{" "}
                         </Box>
                       ))}
                   </>
                 );
               case "report":
               default:
                 return (
                   <>
                     {recordsData?.map((row, index) => (
                       <RecordItem
                         key={row.id} // Ensure to add a unique key for each item
                         reportType={recordType}
                         fetchUrlForFile={() => handleListItemClick(row)}
                         setStoreData={setStoreData}
                         menuItems={
                           recordType === "report"
                             ? menuItemsWithStatusChange
                             : menuItems
                         }
                         isShowDropdown={recordType === "report"}
                         row={row}
                         thumbnailData={thumbnails}
                         index={index}
                       />
                     ))}
                     {total > 9 &&
                       recordsData.length < total &&
                       (!loadingSeeMore ? (
                         <Box>
                           <ListItemWrapper key="see-more">
                             <ListItemTop style={{ visibility: "hidden" }}>
                               hidden
                             </ListItemTop>
                             <SeeMoreWrapper onClick={handleSeeMore}>
                               <SeeMoreText>See More</SeeMoreText>
                               <NextArrow src={INextArrow} />
                             </SeeMoreWrapper>
                             <ListItemDate style={{ visibility: "hidden" }}>
                               hidden
                             </ListItemDate>
                             <ListItemDate style={{ visibility: "hidden" }}>
                               hidden
                             </ListItemDate>
                           </ListItemWrapper>
                         </Box>
                       ) : (
                         <Box
                           display="flex"
                           m={2}
                           alignItems="center"
                           justifyContent="center"
                         >
                           <CircularProgress />{" "}
                         </Box>
                       ))}
                   </>
                 );
             }
           })()}
         </Grid>
         
          ) : (
            <LoaderWrapper>
              <ShimmerContentBlock
                title
                text
                cta
                thumbnailWidth={82}
                thumbnailHeight={82}
              />
            </LoaderWrapper>
          )}
        </>
      </ListWrapper>

      {isShowhangeStatus ? (
        <ChangeStatusDialog
          open={isShowhangeStatus}
          onConfirm={(status) => onStatusAvailable(status)}
          testId={"test"}
          title={"Change file status"}
          discription={
            "Are you sure you want to change the status of the file?"
          }
          closeHandler={() => {
            setShowChangeStatus(false);
            setIsStatusChangeLoading(false);
          }}
          isLoding={issStatusChangeLoading}
          currentStatus={storeData?.status}
        />
      ) : (
        ""
      )}
      <StyledModal open={open} onClose={closeModal}>
        <StyledModalContent>
          <StyledCloseButton onClick={() => setOpen(false)}>
            &times;
          </StyledCloseButton>
          {selectedRecord &&
            (["image/jpeg", "image/png"].includes(
              selectedRecord.content_type
            ) || selectedRecord?.record_type === "image" ? (
              <StyledImage src={selectedRecord.url} alt={selectedRecord.name} />
            ) : (
              <Worker workerUrl={workerUrl}>
                <Viewer fileUrl={selectedRecord.url} />
              </Worker>
            ))}
        </StyledModalContent>
      </StyledModal>
      <SnackBar />
    </Container>
  );
};

export default SeeMoreRecords;
