import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  BackButtonContainer,
  BackArrowImg,
  BackText,
  HeadText,
  RecordsListContainer,
  PreviewImage,
  StyledTableCellHead,
  CategoryDropdownStyled,
  StyledTable,
  StyledTableBody,
  StyledTableCell,
  StyledTableContainer,
  StyledTableHead,
  StyledTableRow,
  StyledTableCellImage,
  DeleteIcon,
  NextButton,
  ParentContainer,
  StyleTableName,
  ValidationError,
} from "./style.components";
import { Select, MenuItem, Box } from "@mui/material";
import { IBackArrow, IDeleteIcon } from "../../assets";
import StatusDropdown from "../../components/shared/StatusDropdown/StatusDropdown";
import { pollRecordsAction, sendRecordsAction } from "../../store/actions";
import { patientSelector } from "../../store/slice/patient.slice";
import { Loader, SnackBar } from "../../components/shared";
import { recordsUppySelector } from "../../store/slice/recordsUppy.slice";
import { splitPdfAction } from "../../store/actions";

const categoryOptions = [
  { label: "Report", value: "report" },
  { label: "Prescription", value: "prescription" },
  { label: "Medical Letter", value: "medical letter" },
];

const dropdownOptions = [
  { label: "Available", value: "AVAILABLE" },
  { label: "Restricted", value: "RESTRICTED" },
];

const SendRecords = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { records, uppyResponse } = useSelector(recordsUppySelector);
  const { selectedPatientId } = useSelector(patientSelector);
  const [recordList, setRecordList] = useState(records || []);
  const [isLoading, setIsLoading] = useState(false);
  const [polling, setPolling] = useState(!(location.pathname === "/new-encounter/add-records/send-records"));
  const [validationMessages, setValidationMessages] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState(
    records ? records.map(() => dropdownOptions[0].value) : []
  );
  const [selectedCategories, setSelectedCategories] = useState(records ? records.map(() => "") : []);
  const [splittingRecords, setSplittingRecords] = useState({});
  const [splitRecords, setSplitRecords] = useState({});
  const isNewEncounter = location.pathname === "/new-encounter/add-records/send-records";
  const [isCategoryLoading, setIsCategoryLoading] = useState(true); // Initially set to true

  const eligibleForSplit = recordList.some(
    (record) => record?.document_ranges?.length > 0 && !splitRecords[record?.converted_file_name]
  );
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(true);

  useEffect(() => {
    if (uppyResponse?.uploadedFiles) {
      setRecordList((prevRecordList) =>
        prevRecordList.map((record) => {
          const matchedFile = uppyResponse.uploadedFiles.find(
            (file) => file.file_name === record.converted_file_name
          );
          if (matchedFile) {
            return {
              ...record,
              file_id: matchedFile.file_id,
              file_name: matchedFile.file_name,
              mimetype: matchedFile.mimetype,
              file_size: matchedFile.file_size,
            };
          }
          return record;
        })
      );
    }
  }, [uppyResponse]);

  useEffect(() => {
    // Set default categories for transcription, summary, and prescription files
    const updatedCategories = selectedCategories.map((category, index) => {
      const fileName = recordList[index]?.file_name?.toLowerCase() || "";
      if (fileName.includes("transcription") || fileName.includes("summary")) {
        return "medical letter";
      } else if (fileName.includes("prescription")) {
        return "prescription";
      }
      return category;
    });
    setSelectedCategories(updatedCategories);
  }, [recordList]);

  useEffect(() => {
    if (polling) {
      const pollingInterval = setInterval(() => {
        getPollRecords();
      }, 4000);
  
      // Stop polling after 32 seconds
      const timeoutId = setTimeout(() => {
        clearInterval(pollingInterval);
        setPolling(false);
      }, 32000);
  
      return () => {
        clearInterval(pollingInterval);
        clearTimeout(timeoutId);
      };
    }
  }, [polling]);
  

  useEffect(() => {
    const hasValidationErrors = validationMessages.some((msg) => msg !== "");
    const areAllCategoriesSelected = selectedCategories.every((category) => category !== "");
    setIsAddButtonDisabled(hasValidationErrors || !areAllCategoriesSelected);
  }, [validationMessages, selectedCategories]);

  const getPollRecords = async () => {
    try {
      const response = await dispatch(pollRecordsAction(uppyResponse?.assemblyID));
      if (response?.payload?.files) {
        const updatedCategories = [...selectedCategories];
        const updatedValidationMessages = [...validationMessages];
        const updatedRecordList = [...recordList];

        response?.payload?.files.forEach((file) => {
          const recordIndex = recordList.findIndex(
            (record) => record?.converted_file_name === file.file_name
          );

          if (recordIndex !== -1 && !splitRecords[file.file_name]) {
            const { contains_multiple_types, document_ranges } = file.classification_result;

            if (contains_multiple_types) {
              updatedCategories[recordIndex] = "";
              updatedValidationMessages[recordIndex] = "Multiple categories detected";
            } else {
              const recordType = document_ranges[0]?.record_type;
              if (recordType !== "Other") {
                updatedCategories[recordIndex] = recordType.toLowerCase();
                updatedValidationMessages[recordIndex] = "";
              } else {
                updatedCategories[recordIndex] = "";
                updatedValidationMessages[recordIndex] = "Could not detect category";
              }
            }

            updatedRecordList[recordIndex] = {
              ...updatedRecordList[recordIndex],
              document_ranges,
              contains_multiple_types
            };
          }
        });

        setSelectedCategories(updatedCategories);
        setValidationMessages(updatedValidationMessages);
        setRecordList(updatedRecordList);
        if (response?.payload?.files?.length === records.length) {
          setPolling(false);
        }
        if (response?.payload?.files?.length > 0) {
          setPolling(false);
          setIsCategoryLoading(false); 
        }
      }
    } catch (error) {
      console.error("Error fetching records:", error);
    }
  };

  const handleBackClick = () => {
    window.history.back();
  };

  const handleStatusChange = (index) => (event) => {
    const newStatuses = [...selectedStatuses];
    newStatuses[index] = event.target.value;
    setSelectedStatuses(newStatuses);
  };

  const handleCategoryChange = (index) => (event) => {
    const newCategories = [...selectedCategories];
    const newValidationMessages = [...validationMessages];
    newCategories[index] = event.target.value;
    if (newCategories[index] !== "") {
      newValidationMessages[index] = "";
    }
    setSelectedCategories(newCategories);
    setValidationMessages(newValidationMessages);
  };

  const handleRemoveRecord = (index) => {
    setRecordList((prevList) => prevList.filter((_, i) => i !== index));
  };

  const handleSplitAll = async () => {
    const eligibleRecords = recordList.filter(
      (record) => record?.document_ranges?.length > 0  && record?.contains_multiple_types  && !splittingRecords[record?.converted_file_name]
    );
  
    const splittingState = eligibleRecords.reduce((acc, record) => {
      acc[record.converted_file_name] = true;
      return acc;
    }, {});
    setSplittingRecords(splittingState);
  
    const splitPromises = eligibleRecords?.map((record) => {
      const { document_ranges, file_gcp_location } = record;      
      const splitDetails = document_ranges.map((range) => ({
        page_range: `${range.start_page}-${range.end_page}`,
        type: range.record_type.toLowerCase(),
        record_name: range.record_name,
      }));
  
      const payload = {
        intended_split_pdf_path: file_gcp_location,
        split_details: splitDetails,
      };
  
      return dispatch(splitPdfAction(payload)).unwrap();
    });
  
    try {
      const splitResponses = await Promise.all(splitPromises);
      const newSplitFiles = splitResponses.flatMap((response) => response.split_files);
  
      let updatedRecords = recordList.filter(
        (record) => !record?.contains_multiple_types
      );
  
      const newRecords = newSplitFiles.map((file) => ({
        converted_file_name: file.file_name,
        thumbnail_gcp_location: file.thumbnail_url,
        file_gcp_location: file.uploaded_file_url,
        file_type: file.file_type.toLowerCase(),
        status: "AVAILABLE", 
        document_ranges: [],
      }));
  
      updatedRecords = [...updatedRecords, ...newRecords];
  
      setRecordList(updatedRecords);
  
      const newCategories = [...selectedCategories];
      const newStatuses = [...selectedStatuses];
      const newValidationMessages = [...validationMessages];
  
      newRecords.forEach((file, i) => {
        const index = updatedRecords.findIndex((r) => r.converted_file_name === file.converted_file_name);
        newCategories[index] = file.file_type.toLowerCase();
        newStatuses[index] = "AVAILABLE";
        newValidationMessages[index] = "";
      });
  
      setSelectedCategories(newCategories);
      setSelectedStatuses(newStatuses);
      setValidationMessages(newValidationMessages);
    } catch (error) {
      console.error("Error splitting files:", error);
    } finally {
      setSplittingRecords({});
    }
  };

  const handleAddRecords = async () => {
    try {
      setIsLoading(true);
  
      // Check if all required fields are present in recordList
      const groupedRecords = recordList.reduce((acc, record, index) => {
        const fileType = selectedCategories[index]?.toLowerCase().replace(" ", "_");
  
        if (!acc[fileType]) {
          acc[fileType] = [];
        }
  
        // Ensure all fields are being included in the payload
        acc[fileType].push({
          file_id: record?.file_id, // Ensure file_id is included
          mimetype: record?.mimetype, // Ensure mimetype is included
          file_size: record?.file_size, // Ensure file_size is included
          file_name: record?.converted_file_name, // Ensure file_name is included
          file_type: fileType,
          uploaded_file_url: record?.uploaded_file_url || record?.file_gcp_location, // Ensure uploaded_file_url is included
          status: selectedStatuses[index],
        });
  
        return acc;
      }, {});
  
      // Send API call for each fileType group
      const sendRecordPromises = Object.keys(groupedRecords).map((fileType) => {
        const payload = {
          patient_id: selectedPatientId,
          report_info: groupedRecords[fileType], // Ensure all fields are part of the report_info
        };
  
        return dispatch(sendRecordsAction({ type: fileType, payload })).unwrap();
      });
  
      const responses = await Promise.all(sendRecordPromises);
  
      // Check if all responses are successful
      const allSuccess = responses.every((response) => response?.status === "success");
      if (allSuccess) {
        if (isNewEncounter) {
          navigate(-3); // Navigate back 3 pages for new encounter
        } else {
          navigate(-1, { replace: true }); // Replace current history entry
        }
      }
    } catch (error) {
      console.error("Error adding records:", error);
    } finally {
      setIsLoading(false);
    }
  };  

  return (
    <ParentContainer>
      <BackButtonContainer onClick={handleBackClick}>
        <BackArrowImg src={IBackArrow} />
        <BackText>{`Back`}</BackText>
      </BackButtonContainer>
      <HeadText>Here is the system detected details, please confirm and edit as required</HeadText>
      {!isLoading ? (
        <RecordsListContainer>
          <StyledTableContainer>
            <StyledTable>
              <StyledTableHead>
                <StyledTableRow>
                  <StyledTableCellHead>Preview</StyledTableCellHead>
                  <StyledTableCellHead>File Name</StyledTableCellHead>
                  <StyledTableCellHead>Category</StyledTableCellHead>
                  <StyledTableCellHead>Status</StyledTableCellHead>
                  <StyledTableCellHead>Remove</StyledTableCellHead>
                </StyledTableRow>
              </StyledTableHead>
              <StyledTableBody>
                {recordList.map((record, index) => (
                  <StyledTableRow key={index}>
                    {splittingRecords[record.converted_file_name] ? (
                      <StyledTableCell colSpan={6} style={{ textAlign: "center" }}>
                        <Loader text={"Splitting record..."} />
                      </StyledTableCell>
                    ) : (
                      <>
                        <StyledTableCellImage>
                          <PreviewImage src={record?.thumbnail_gcp_location} alt={record?.converted_file_name || record?.file_name} />
                        </StyledTableCellImage>
                        <StyledTableCell>
                          <StyleTableName>{record?.converted_file_name || record?.file_name}</StyleTableName>
                        </StyledTableCell>
                        <StyledTableCell>
                          {isCategoryLoading ? (
                            <Loader height={"20px"} /> // Show Loader while waiting for poll response
                          ) : (
                            <CategoryDropdownStyled
                              value={categoryOptions.some((option) => option.value === selectedCategories[index])
                                ? selectedCategories[index]
                                : ""}
                              onChange={handleCategoryChange(index)}
                              displayEmpty
                              input={<Select variant="outlined" fullWidth />}
                              disabled={validationMessages[index] === "Multiple categories detected"}
                            >
                              <MenuItem value="" disabled>
                                Select Category
                              </MenuItem>
                              {categoryOptions.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </CategoryDropdownStyled>
                          )}
                          {validationMessages[index] && <ValidationError>{validationMessages[index]}</ValidationError>}
                        </StyledTableCell>
                        <StyledTableCell>
                          <Box mt={1} mb={1}>
                            <StatusDropdown
                              selectedStatus={selectedStatuses[index]}
                              handleChange={handleStatusChange(index)}
                              dropdownOptions={dropdownOptions}
                            />
                          </Box>
                        </StyledTableCell>
                        <StyledTableCell>
                          <DeleteIcon src={IDeleteIcon} onClick={() => handleRemoveRecord(index)} />
                        </StyledTableCell>
                      </>
                    )}
                  </StyledTableRow>
                ))}
              </StyledTableBody>
            </StyledTable>
          </StyledTableContainer>
          <div style={{ display: "flex", justifyContent: "flex-end", gap: "20px" }}>
            {!isNewEncounter && (
              <NextButton onClick={handleSplitAll} disabled={!eligibleForSplit || Object.keys(splittingRecords).length > 0}>
                {Object.keys(splittingRecords).length > 0 ? <Loader height={"0"} /> : "Split All"}
              </NextButton>
            )}
            <NextButton disabled={isAddButtonDisabled} onClick={handleAddRecords}>
              Add Records
            </NextButton>
          </div>
        </RecordsListContainer>
      ) : (
        <Loader text={"Adding record..."} />
      )}
      <SnackBar />
    </ParentContainer>
  );
};

export default SendRecords;
